
// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
// @import '@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
// @include mat.all-legacy-component-typographies();
// @include mat.legacy-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$B2Bstore-primary: mat.define-palette(mat.$indigo-palette);
$B2Bstore-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$B2Bstore-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$B2Bstore-theme: mat.define-light-theme((
  color: (
    primary: $B2Bstore-primary,
    accent: $B2Bstore-accent,
    warn: $B2Bstore-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
// @include mat.all-legacy-component-themes($B2Bstore-theme);
// @include mat.define-light-theme($B2Bstore-theme);

/* You can add global styles to this file, and also import other style files */

$styleVersion: 5.0;
@import 'node_modules/@spartacus/styles';

// Owl Carousel
@import 'node_modules/ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import 'node_modules/ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';

// Helpers
@import 'style/helpers/mixins';
@import 'style/helpers/variables';

//generic
@import 'style/mobile.scss';
@import 'style/font.scss';
@import 'style/buttons.scss';
@import 'style/cards.scss';
@import 'style/carousel.scss';
@import 'style/forms.scss';
@import 'style/typography.scss';

//pages components
@import 'style/header.scss';
@import 'style/page.scss';

@import 'style/login.scss';
@import 'style/homepage.scss';
@import 'style/change-password.scss';
@import 'style/claim-search.scss';
@import 'style/product-detail.scss';
@import 'style/product-list.scss';
@import 'style/search-results-list.scss';
@import 'style/checkout-order-confirmation';
@import 'style/custom-order-history-template.scss';
@import 'style/grid.scss';
@import 'style/tables.scss';
@import 'style/footer.scss';
@import 'style/pagination.scss';

//style
html, body { 
    height: 100%; 
}

body { 
    margin: 0;
}

a {
    color: $blue;
    &:hover{
        color: #00a1de !important;
        cursor: pointer;
    }
}

.addWhishlist a:hover{
  text-decoration: none;
  color: #37424A !important;
}

.addWhishlist i.far.fa-star.whishlist, .addWhishlist i.fas.fa-star.whishlist {
  font-size: 20px;
  margin-right: 2%;
}
cx-page-layout.LandingPage2Template cx-page-slot.Section2B > * {
    --cx-flex-basis: 100% !important;
}
cx-page-layout.AccountPageTemplate cx-page-slot {
    padding-top: 40px !important;
    @include respond-below('md') {
        padding-top: 10px !important;
        padding: 10px !important;
    }
}
// Checkout Pages
cx-page-layout.MultiStepCheckoutSummaryPageTemplate {
    background: $lightestgrey;
    max-width: $container-max-width !important;
    @include respond-below('sm') {
        padding: 20px 0 !important;
    }
}
cx-storefront.MultiStepCheckoutSummaryPageTemplate main {
    background: $lightestgrey !important;
}
.box-shadow-none {
    box-shadow: none;
}
.color-orange {
    color: $orange !important;
}
.color-black {
    color: $black !important;
}
.color-blue {
    color: $blue !important;
}
.color-darkgrey {
    color: $darkgrey !important;
}
.border-darkgrey {
    border-color: $darkgrey !important;
}
.bg-blue {
    background-color: $blue !important;
}
.bg-transparent {
    background-color: transparent !important;
}
.bg-darkgrey {
    background-color: $darkgrey !important;
}
.bg-lightgrey {
    background-color: $lightgrey !important;
}
.bg-lightestgrey {
    background-color: $lightestgrey !important;
}
.bg-modal-header-bg {
    background-color: $modal-header-bg !important;
}
.owl-carousel-wrapper .owl-stage {
    /* display: flex; */
    display: -webkit-box;
}
.owl-carousel {
    width: 100%;
    max-width: 90vw;
}

@media print {
    html {
        border: none !important;
    }
    * {
        box-shadow:         none !important; 
    }
}
/* Importing Bootstrap SCSS file. */
@import 'node_modules/bootstrap/scss/bootstrap';

 
ngb-modal-backdrop.show {
    z-index: 1050 !important;
  }

 .NavigationBar nav {
    
    justify-content: flex-end;
    flex-flow: wrap;
    padding-top: 0px;
    display: flex;

  }

  .NavigationBar nav ul {
    
    justify-content: flex-end;
  }  
  @media (min-width: 992px) 
  {
    
    cx-category-navigation cx-navigation-ui>nav>ul>li {
        padding-inline-start: 15px;
        padding-inline-end: 0;
    } 
}  
   cx-generic-link a {
    font-family: formata-medium!important;
    color: #37424a;
    font-size: 14px;
      }
      cx-page-layout.MultiStepCheckoutSummaryPageTemplate cx-page-slot.SideContent {
        flex: 0 0 35%;
    }
    cx-page-layout.MultiStepCheckoutSummaryPageTemplate cx-page-slot.BodyContent {
        flex: 0 63%;
    }


    cx-page-layout.MultiStepCheckoutSummaryPageTemplate {
        max-width: var(--cx-page-width-max);
        padding-top: 2rem;
        padding-inline-end: 1.5rem;
        padding-bottom: 0;
        padding-inline-start: 1.5rem;
        margin: auto;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-content: flex-start;
    }


    .card.__second-cart .card-body .form-check, .card.__third-cart .card-body .form-check {
        padding-inline-start: 2.25rem;
    }

    .card.__second-cart .card-body label {
        display: block;
    }

    
    .card.__third-cart .card-body .form-check label {
        width:100%;
    }

    .card.__first-cart label.__addnew-address-btn{
        width:100%;
    }

    header .header .SearchBox cx-searchbox input {
        height: 35px;
    }

    //Custom Code

    .asm-bar {
        background-color: #364b60;
        
        display: flex;
      }
      
      .asm-bar-branding {
        padding: 8px 1%;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        flex-grow: 1;
      }
      
      .asm-bar-actions {
      
        display: flex;
        justify-content: flex-end;
        flex-grow: 1;
      }
      
      
      .asm-bar-actions .close {
        
        padding: 9px;
        margin: 11px;
        position: relative;
      }
      
      cx-asm-toggle-ui {
        position: relative;
      }
      
      .asm-bar-actions  cx-asm-toggle-ui::before{
      
        background-image: url('assets/uparrow.png');
        content: '';
        width: 16px;
        height: 14px;
        display: block;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        left: -13px;
        top: 13px;
      }
      
      
      
        cx-asm-session-timer .reset {
          position: relative;
          float: right;
          margin: 0px 8px;
            margin-top: 0px;
          background-color: unset;
          border: unset;
          margin-top: 3px;
        }
      
      cx-asm-session-timer .reset::before,.asm-bar-actions .close::before {
        
        background-image: url('assets/reseticon.png');
        content: '';
        width: 16px;
        height: 14px;
        display: block;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        left: 0;
        top: 0;
      }
      
      
      .asm-bar-actions .logout {
        position: relative;
        float: right;
        margin: 0px 9px;
        margin-top: 0px;
        background-color: unset;
        border: unset;
        margin-top: 3px;
        margin: 13px 15px 0px 0px;
      }
      
      .asm-bar-actions .logout::before {
        
        background-image: url('assets/onofficon.png');
        content: '';
        width: 16px;
        height: 14px;
        display: block;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        left: 0;
        top: 0;
      }
      
      
      
      
      .asm-bar-branding .asm-title {
        color: white;
        padding: 0 5px;
        display: block;
        float: left;
        float: left;
        display: block;
        margin: 0px 10px;
        font-weight: bold;
      }
      .asm-bar-branding img {
        vertical-align: middle;
        border-style: none;
        float: left;
      }
      
      cx-customer-emulation ,cx-customer-selection ,cx-csagent-login-form {
        display: block;
        margin: 1em 1em;
      }
      
      
      cx-customer-emulation input, cx-customer-selection input,cx-csagent-login-form input {
        border-radius: 5px;
        height: 2rem;
        margin: 5px;
        padding: 5px;
      }
      
      cx-customer-emulation input, cx-customer-selection label,cx-csagent-login-form label {
        display: inline-block;
        margin-bottom: .375rem;
        position: relative;
      }
      
      cx-form-errors {
        position: absolute;
        left: 0px;
        top: 2.9rem;
      }
      
      cx-customer-emulation button , cx-customer-selection button[type="submit"] ,cx-csagent-login-form button[type="submit"] {
        color: white;
        padding: 5px 11px;
        border-radius: 4px;
        margin-left: 5px;
        border: unset;
      }
      
      cx-customer-emulation input ,cx-customer-selection label {
        display: inline-block;
        margin-bottom: .375rem;
        position: relative;
        max-width: 100%;
        width: 350px;
      }

       cx-customer-selection label input{
        width: 98%
      }
      
      cx-asm-main-ui {
        background-color: aliceblue;
      }
      
      
      cx-customer-emulation, cx-customer-selection {
        position: relative;
        display: block;
      }
      
      cx-customer-emulation .asm-results,cx-customer-selection .asm-results {
        width: 30%;
        position: absolute;
        background: #fff;
        z-index: 99;
        box-shadow: 5px 10px 8px #8888883b;
        border-radius: 7px;
        border: 1px solid gray;
        padding: 6px 14px;
      }
      
      cx-customer-selection .asm-results button {
        display: block;
        width: 100%;
        text-align: left;
        height: 40px;
        border: unset;
          border-bottom-width: unset;
          border-bottom-style: unset;
          border-bottom-color: unset;
        background: unset;
        border-bottom: 1px solid #ddd;
      }
      
      
      cx-customer-selection .asm-results button:hover {
        background-color: aliceblue;
      }
      
      cx-asm-main-ui cx-customer-emulation  button  {
        background-color: unset;
        color:#bf0303;
        border: 2px solid #bf0303;
        border-radius: 0px;
      
      }
      .asm-bar-actions cx-asm-toggle-ui {
        padding: 10px;
      }
      .asm-bar-actions cx-asm-session-timer {
        color: white;
        font-weight: bold;
        padding: 10px;
        margin: 0px 11px;
      }
      .asm-bar-actions .cx-asm-help-portal, 
      .asm-bar-actions .cx-asm-customer-list {
        display: none;
      }
      
      /*-------------------------------------------RADIO BUTTON STYLE--------------------------------------------------*/
      cx-page-slot form .__saved-address-radio label {
        font-family: formata-light !important;
        color: #37424a;
        font-size: 16px;
        line-height: 20px !important;
        height: 40px;
        background: unset;
        padding: 0 9px;
      }

      .ng-star-inserted .form-check input[type=checkbox], .form-check input[type=radio] {
  
        appearance: none;
        height: 18px;
        width: 18px;
        cursor: pointer;
        line-height: 1.42857;
        appearance: none;
        -webkit-appearance: textfield;
        -moz-appearance: textfield;
        font-size: inherit;
        box-sizing: border-box;
        outline: none;
        border-style: solid;
        border-width: 1px;
        position: absolute;
        margin-top: 0rem;
    }
    cx-customer-emulation {
      display: flex;

      cx-asm-bind-cart {
        label[for="cartNumber"],
        .cx-asm-reset,
        .cx-asm-bindCartToCustomer {
          display: none;
        }
      }

      .cx-asm-customerInfo {
        position: absolute;
        z-index: 2;
        top: 12px;
        left: 15px;
      }

      button[formcontrolname="logoutCustomer"] {
        height: 32px;
        margin-top: 5px;
      }

      label {
        color: #999;
      }

      input[formcontrolname="cartNumber"] {
        color: transparent;
        caret-color: transparent;
        pointer-events: none;
      }

      input.ng-invalid {
        border-color:#ccc !important;
      }
    }

    cx-asm-main-ui cx-message {
      display: none;
    }