@font-face {
    font-family: "formata-regular";
    src: local("formata"), url(.././assets/fonts/formata/Formata-Regular.otf) format("truetype");
}
@font-face {
    font-family: "formata-medium";
    src: local("formata"), url(.././assets/fonts/formata/Formata-Medium.otf) format("truetype");
}
@font-face {
    font-family: "formata-light";
    src: local("formata"), url(.././assets/fonts/formata/Formata-Light.otf) format("truetype");
}

* {
    font-family: 'formata-light', 'arial', sans-serif;
}

.mat-h5, .mat-typography h5, .mat-h3, .mat-subheading-2, .mat-typography h3 {
    /* font-family: 'formata-light', 'arial', sans-serif !important; */
    @include formata('light');
}

body {
    /* font-family: 'formata-light', 'arial', sans-serif; */
    @include formata('light');
}

cx-generic-link > a{
    @include formata('light');
    font-weight: 100;
}

.mat-h1, .mat-headline, .mat-typography h1{
    /* font-family: 'formata-light', 'arial', sans-serif !important; */
    @include formata('light');
}