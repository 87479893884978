// Move this to global styles
.card {
    
    /* box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%); */
    @include box-shadow();
    max-width: 1080px;
    margin: auto;
    overflow: hidden;
    
    .nav-pills {
        flex-wrap: no-wrap;
        justify-content: space-around;
        flex-wrap: nowrap;
        gap: 10px;
        li {
            /* min-width: 226px; */
            min-width: auto;
            width: 100%;
        }
        a {
            @include formata('regular');
            letter-spacing: .5px;
            color: $white !important;
            font-size: 15px;
            background-color: $blue;
            text-transform: uppercase;
            border: 2px solid $blue;
            border-radius: 30px;
            height: 52px;
            padding-left: 30px;
            padding-right: 30px;
            position: relative;
            text-align: left;
            width: 100%;
            justify-content: space-between;
            display: flex;
            align-items: center;
            padding-top: 2px;
            &:hover {
                text-decoration: none !important;
                background-color: $darkblue;
                color: $white !important;
            }
            &:after {
                color: $darkgrey;
                content: "\f054";
                font-family: "Font Awesome 5 Free";
                font-weight: 900;
                position: absolute;
                right: 16px;
            }
            &.active {
                background: $white;
                color: $blue !important;
                &:after {
                    transform: rotate(90deg);
                }
            }
        }
       
    }
    
    .card-title {
        @include formata('medium');
        font-size: 24px;
        margin: 20px 0 30px;
        line-height: 29px;
        text-transform: uppercase;
    }
    h4 {
        @include formata('medium');
        font-size: 16px;
        line-height: 28px;
        text-transform: uppercase;
    }
    .card-body {
        padding: 30px;
        .tab-pane {
            border: 1px solid $lightestgrey;
            border-radius: 10px;
            margin-top: 40px;
            padding: 35px 50px;
        }
        a {
            color: $blue;
            cursor: pointer;
        }
    }
    &.plain {
        border: 0;
        box-shadow: none;
        padding-bottom: 20px;
        .card-body {
            color: $darkgrey;
            padding: 20px 5px 20px 2px;
        }
        a {
            @include formata('light');
            color: $blue;
        }
    }
}