cx-page-slot.LeftContentSlot > cx-paragraph{
    display: none;
}

cx-page-layout.ProductListPageTemplate{
    // padding: 3% 6% !important;
    background: var(--cx-color-background) !important;  
    max-width: 100% !important;
}
cx-page-layout.CartPageTemplate{
    // padding: 3% 6% !important;
    background: var(--cx-color-background) !important;  
    max-width: 100% !important;
    padding: 0 !important;
}
cx-page-layout.ContentPage1Template{
    // padding: 3% 6% !important;
    background: var(--cx-color-background) !important;  
    max-width: 100% !important;
}