cx-page-slot {
    form {
        label {
            @include formata('light');
            color: $darkgrey;
            font-size: 16px;
            line-height: 20px;
            span {
                color: #6D767C;
                display: inline-block;
                margin-left: 14px;
            }
        }
        textarea, input {
            &.invalid {
                border-color: $red !important;
            }
        }
        .invalid-feedback {
            @include formata('regular');
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
            font-size: 12px;
            line-height: 14px;
            margin-top: 0;
            background-color: $red;
            color: $white;
            padding: 10px 10px 10px 10px;
        }

        input.ng-touched, input.ng-dirty{
            border-color: $lightgrey !important;
        }
        textarea, input {
            ~ .invalid-feedback {
                margin-top: 0;
            }
        }
    }
    .form-control {
        border-color: $lightgrey;
        border-radius: 5px;
        width: auto;
        //min-width: 300px;
        @include respond-below('sm') {
            min-width: 100%;
        }
        
        &.invalid, &.is-invalid {
            border-color: $red !important;
            margin-bottom: 0;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
        
    }
    input {
        &[type=radio] {
            border-color: $lightgrey !important;
            &:checked {
                border-color: $lightgrey !important;
                &::after {
                    background-color: $lightgrey;
                    width: 12px;
                    height: 12px;
                    margin: auto;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                }
            }
        }
        ~ label {
            font-size: 16px;
            line-height: 32px;
        }
        &.is-invalid {
            border-color: $red;
            margin-bottom: 0;
        }
    }
    select {
        @include formata('regular');
        border: 1px solid $blue;
        border-radius: 30px;
        color: $blue;
        height: 48px;
        font-size: 15px;
        line-height: 18px;
        padding: 0 20px;
        max-width: 232px;
        text-transform: uppercase;
        @include respond-below('md') {
            width: 100%;
            max-width: 100%;
        }
    }
}