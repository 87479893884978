// Set up color variables
$black: #000000;
$white: #ffffff;
$red: #E6355E;

$darkestblue: #173C5D;
$darkblue: #002776;
$mediumblue: #00759A;
$blue: #00A1DE;
$lightblue: #85CDDB;

$green: #1E9D8B;
$lightgreen: #CED64B;

$orange: #FF6D22;
$yellow: #F8DE6E;

$maroon1: #6A1A41;
$maroon2: #6B1948;
$purple: #4B306A;
$lavendar: #A5A2C6;

$darkgrey:#37424A;
$lightgrey: #A5ACAF;
$lightestgrey: #EDEFF0;
$darkestgrey:#273036;

$transparent: transparent;

$opacity: rgba(0,0,0,.0);


$container-max-width: 1400px;
$border-radius: 10px;
$modal-header-bg: #EEF4F6;
$search: #F8F9F9;

// FILTERS
$blue-filter: invert(55%) sepia(69%) saturate(3872%) hue-rotate(164deg) brightness(94%) contrast(102%);
$darkestblue-filter: invert(22%) sepia(12%) saturate(2852%) hue-rotate(167deg) brightness(88%) contrast(95%);
