cx-page-layout {
    cx-page-slot{
        max-width: 100% !important;
    }
    &.LandingPage2Template {
        .Section1 {
            margin-top: 0 !important;
        }
    }
    cx-navigation-ui cx-generic-link.all {
        text-decoration: none !important;
    }
}

a[ng-reflect-router-link="/my-account/update-email"], a[ng-reflect-router-link="/my-account/quick-order"], a[ng-reflect-router-link="/my-account/address-book"] {
    display: none !important;
}

header {
    background: $white !important;
    max-width: $container-max-width !important;
    width: 100% !important;
    margin: auto;
    padding-right: 20px;
    padding-left: 20px;
    .header {
      display: grid !important;
      // grid-template-columns: min-content auto max-content;
      grid-template-columns: auto minmax(0,1fr);
      max-width: 100% !important;
      padding: 0 !important;
        .PreHeader {
          grid-column: 1 !important;
          grid-row: 1 / 10;
        }
        .SiteContext {
            display: none;
            &:after {
                border: 0;
            }
        }
        // Combine rows
        .SearchBox, .SiteLogin {
            grid-row: 2;
            // width: fit-content !important;
            body.anonymous & {
                 width: 100% !important;
            }
        }
        .SiteLogin {
            grid-column: 4 / span 1 !important;
            grid-column: 4/span 3 !important;
            grid-column: 3 !important;
            width: 100% !important;
            padding: 0;
            body.anonymous & {
                display: none;
            }
        }
        .SearchBox {
            grid-column: 2 / span 2 !important;
            grid-column: 2 !important;
            width: 100% !important;
            display: flex;
            justify-content: end;
            z-index: 1;
            @include respond-above('md') {
                margin-top: 0;
                body.anonymous & {
                    margin-top: 55px;
                }
            }
            cx-searchbox {
                @include respond-above('md') {
                    //display: flex;
                    //justify-content: end;
                }
                label {
                    @include formata('regular');
                    font-size: 13px;
                    line-height: 16px;
                    background-color: $lightestgrey;
                    color: $darkgrey;
                    border-radius: 50px;
                    margin: 15px 20px 0;
                    padding-left: 20px;
                    padding-right: 6px;
                    width: 100%;
                    max-width: 245px;
                    min-width: auto;
                    float: right;
                    @include placeholder() {
                        color: $lightgrey;
                        text-transform: uppercase;
                    }
                    cx-icon {
                        font-size: 18px;
                    }
                }
                cx-icon {
                    color: $blue;
                }
                input {
                    height: 25px;
                }
                .results {
                    background: $blue;
                    max-width: 300px;
                    position: absolute;
                    right: 0;
                    color: $darkgrey;
                    left: auto;
                    top: 52px;
                    .message {
                        display: none !important;
                        padding: 15px;
                    }
                }
            }
        }
        .SiteLinks {
            display: none;
            body.anonymous & {
                display: none;
            }
        }
        .SiteLogo {
          border-right: 1px solid $lightgrey;
          grid-column: 1;
          grid-row: 1/10;
          width: fit-content;
          height: 100%;
          display: flex;
          align-content: center;
          padding-right: 50px;
          img {
                /* width: 215px; */
                width: 100%;
                max-width: 215px;
                min-width: auto;
            }
          body.anonymous & {
              min-height: 160px;
          }
        }
        .MiniCart {
            grid-column: 2/span 2;
            grid-row: 4;
        }
        .NavigationBar {
            grid-column: 2/span 2;
            grid-row: 5;
            cx-navigation-ui {
                background: transparent;
                justify-content: flex-end;
                padding-top: 0;
                flex-flow: wrap;
                &.flyout > nav {
                    > h5, > cx-generic-link > a {
                        @include formata('medium');
                        color: $darkgrey;
                        font-size: 14px;
                        line-height: 18px;
                        margin: 0;
                        margin: 25px 12px;
                        padding: 0;
                        position: relative;
                        text-transform: uppercase;
                        &:last-child {
                            padding-right: 0;
                            margin-right: 0;
                        }
                        &:hover {
                            color: $darkgrey;
                            &:before {
                                content: '';
                                position: absolute;
                                height: 2px;
                                width: 100%;
                                background-color: $blue;
                                bottom: -10px;
                            }
                        }
                    }
                    h5 {
                        margin-right: 5px;
                        &:hover {
                            &:before {
                                width: calc(100% - 18px);
                            }
                        }
                    }
                    .wrapper {
                        background-color: $lightestgrey;
                        padding: 0;
                        cx-generic-link {
                            border-bottom: 1px solid $lightgrey;
                            padding: 15px 20px !important;
                            a {
                                @include formata('medium');
                                color: $darkgrey;
                                font-size: 14px;
                                line-height: 18px;
                                margin: 0;
                                padding: 0;
                                position: relative;
                                text-transform: uppercase;
                                text-decoration: none;
                            }
                            &:hover a:before {
                                content: '';
                                height: 2px;
                                position: absolute;
                                left: 0;
                                right: 0;
                                bottom: -15px;
                                background: $blue;
                            }
                            a:hover {
                                color: $darkgrey !important;
                            }
                        }
                    }
                }
            }
            
           cx-icon {
               color: $blue;
           }
            
        }
        
    }
}

// MOBILE
@include respond-below('md') {
    body.searchbox-is-active.has-searchbox-results cx-searchbox .results {
        display: block;
    }
     header {
         padding-left: 0;
         padding-right: 0;
         .header {
             body.anonymous & {
                 display: flex !important;
                 text-align: center;
             }
             .SiteLogo {
                 border-right: 0;
                 padding: 20px 15px;
                 margin-left: 50px;
                 body.anonymous & {
                     width: 100%;
                     border-bottom: 1px solid $lightgrey;
                     margin-left: 0;
                     min-height: 100px;
                 }
                 img {
                     width: 175px;
                 }
             }
             .MiniCart {
                 //display: none;
                 grid-row: 2;
                grid-column: 3;
                margin-top: 10px;
                margin-right: 10px;
                @include respond-below('sm') {
                    margin-right: 5px;
                }
                z-index: 2;
             }
             .SearchBox {
                 display: none;
                 text-align: center;
                 margin-top: 14px;
                 z-index: 1;
                 grid-row: 2;
                 grid-column: 2;
                 body.anonymous & {
                     display: block;
                 }
                 cx-searchbox {
                     label {
                         width: 200px;
                         min-width: 200px;
                     }
                 }
                 display: block;
                 @include respond-below('sm') {
                     input {
                         display: none;
                         body.searchbox-is-active & {
                            display: block;
                         }
                     }
                     cx-searchbox {
                         label {
                            background-color: transparent;
                            margin: 0 10px 0 0;
                            width: fit-content;
                            min-width: fit-content;
                            padding: 10px;
                            input {
                                padding: 20px;
                                top: 76px;
                            }
                         }
                         cx-icon {
                             top: 59px;
                         }
                     }
                     .message {
                         padding: 15px;
                     }
                 }
                 .results {
                    .message {
                        display: none !important;
                        padding: 15px;
                    }
                     top: 66px !important;
                     @include respond-below('sm') {
                         top: 117px !important;
                     }
                     max-width: 100% !important;
                     width: 100% !important;
                     body.searchbox-is-active.has-searchbox-results & {
                         display: block;
                     }
                 }
                 .suggestions {
                     text-align: left;
                  }
                 .products {
                     body.searchbox-is-active.has-searchbox-results & {
                         display: block !important;
                     }
                     a.has-media {
                        display: flex;
                        img {
                             max-width: 50px;
                        }
                     }
                 }
             }
             .PreHeader {
                 body.anonymous & {
                     display: none;
                 }
             }
             
         }
         &.is-expanded {
             .MiniCart {
                 display: block;
             }
             .SearchBox {
                 display: none;
             }
             .SiteContext {
                 display: none;
             }
             .SiteLinks {
                 display: none;
             }
             .SiteLogo {
                 /* display: none; */
                 z-index: 3;
             }
             .PreHeader {
                 width: 60px;
             }
         }
     }           
}
