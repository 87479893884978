cx-page-slot.Footer {
    display: grid;
    @include respond-above('md') {
        grid-template-columns: 60% min-content;
        max-width: $container-max-width !important;
        padding-left: 20px;
        padding-right: 20px;
    }
    justify-content: space-between;
    align-items: center;
    align-content: space-around;
    margin: auto;
    padding-bottom: 30px;
    .footer__start {
        border-bottom: 1px solid $white;
        padding-top: 80px;
        padding-bottom: 20px;
        grid-column: 1 / span 2;
        grid-row: 1;
        max-width: 100%;
    }
    .footer__tagline {
        @include formata('regular');
        color: $white;
        font-size: 21px;
        display: flex;
        height: 100%;
        flex-direction: column-reverse;
        align-items: baseline;
        @include respond-below('md') {
            align-items: center;
        }
    }
    .footer__logo {
        float: right;
        @include respond-below('md') {
             float: none;
             text-align: center;
             padding: 40px;
        }
    }
    cx-footer-navigation {
        grid-row: 2;
        max-width: $container-max-width;
        @include respond-below('md') {
            grid-column: 1 / span 2;
        }
    }
    cx-navigation-ui {
        background-color: #37424a !important;
        @include formata('regular');
        justify-content: space-between;
        padding: 5px;
        h5 {
            color: $blue;
            font-size: 15px;
            margin-bottom: 15px;
        }
        a {
            @include formata('light');
            font-size: 17px;
            display: inline-block;
            padding-bottom: 5px;
            color: currentColor;
        }
        > nav {
            width: 100%;
            @include respond-above('md') {
                width: max-content;
                margin-left: 0;
            }
        }
    }
    cx-site-context-selector {
        border: 0;
        grid-row: 3;
        padding: 0;
        @include respond-above('md') {
            grid-row: 2;
            margin:  -80px -10px 0;
        }
        @include respond-below('md') {
            grid-column: 1 / span 2;
            text-align: center;
        }
        label {
            display: inline-block !important;
            min-width: 310px;
            width: auto;
            position: relative;
            span {
                display: none !important;
            }
        }
        select {
            background: $darkestgrey;
            border: 1px solid $white;
            cursor: pointer;
            font-size: 15px;
            padding: 15px 60px 15px 30px;
            margin-right: -18px;
            border-radius: 40px;
            margin: 0;
            min-width: 300px;
            text-transform: uppercase;
            color: currentColor;
        }
        cx-icon {
            position: absolute;
            top: 18px;
            right: 35px;
            color: $blue;
            &:before {
                font-size: 26px;
            }
        }
    }
}
.footer__end {
    background: $darkestgrey !important;
    padding: 30px 20px 15px;
    .container {
        max-width: $container-max-width;
    }
    .footer__legal {
        @include formata('light');
        color: $white;
        font-size: 15px;
        line-height: 25px;
        margin-bottom: 15px;
    }
    .footer__links {
        ul {
            li {
                display: inline-block;
                a {
                    color: $white !important;
                }
                ~ li {
                    margin-left: 10px;
                    padding-left: 10px;
                    border-left: 1px solid $blue;
                }
            }
            @include respond-below('md') {
                text-align: center;
            }
        }
    }
}

cx-page-layout.footer {
    background: $darkgrey !important;
}
/*

cx-page-slot.Footer cx-site-context-selector{
    //margin: 3vw 0vw 3vw 3vw;
    //width: 100% !important;
    //margin: -17% 4% 0;
    //top: -162px;
    top: -190px;
    left: -44px;
}
cx-footer-navigation cx-navigation-ui{
    display: flex !important;
    gap: 120px;
    margin: 0% 2% !important;
    font-family: "formata-light" !important;
    font-size: 17px !important;
    color: #FFFFFF;
}
cx-footer-navigation cx-navigation-ui nav h5{
    font-family: "formata-regular" !important;
    font-size: 15px !important;
    color: #00A1DE;;
}

cx-page-slot.Footer cx-site-context-selector label{
    float: right;
    border: 1px solid #fff;
    border-radius: 20px;
    padding: 10px 30px 10px 30px;
    width: 22%;
    font-family: "formata-regular" !important;
    font-size: 15px !important;
}

cx-page-layout.footer{
    background-color: #37424A !important;
    color: white !important;
}

cx-site-context-selector > label > select{
    background-color: #37424A !important;
}

cx-footer-navigation{
    display: flex;
    width: 100%;
    //padding: 0px 10%;
}

cx-footer-navigation > div.container > cx-navigation-ui > nav{
    width: 20%;
    margin: 1.5vw 3vw 3vw 3vw !important;
}

cx-footer-navigation > div.container > cx-navigation-ui > nav > h5{
    color: #00A1DE;
    font-size: 14px;
}

label.searchbox > input{
    font-size: 10px;
    text-transform: uppercase;
    color: #A5ACAF;
}

cx-page-slot.Footer > cx-paragraph{
    display: none;
}

.endFooter{
    background-color: #273036 !important;
    margin-bottom: -6%;
}

.border-link{
    border-right: 1px solid #00A1DE;
}

.linkFooter{
    padding: 0 5px;
}

cx-footer-navigation > div.container > cx-navigation-ui > cx-site-context-selector{
    padding-left: 70px;
}

cx-footer-navigation > div.container > cx-navigation-ui > cx-site-context-selector > label > cx-icon.small{
    padding-left: 150px;
    // margin-left: 73% !important;
    // color: #00A1DE !important;
}
cx-page-slot.Footer cx-site-context-selector label cx-icon.small{
    margin-left: 73% !important;
    color: #00A1DE !important;
}
cx-footer-navigation > div.container > cx-navigation-ui > nav > div.wrapper > div.childs > nav > cx-generic-link > a{
    font-weight: 100 !important;
}

cx-footer-navigation > div.container > cx-navigation-ui > cx-site-context-selector > label > select{
    text-transform: uppercase;
}

cx-page-slot.MiniCart > cx-navigation > cx-navigation-ui.nav-order-tools{
    display: none;
}

cx-footer-navigation > div.container > cx-navigation-ui {
    justify-content: left !important;
    display: flex;
    gap: 183px;
    margin: 0% 2%;
}

cx-footer-navigation > div.container > cx-navigation-ui > nav:first-child{
    margin-left: 0 !important;
}
*/
cx-footer-navigation cx-navigation-ui nav ul li span{
  font-family: "formata-regular" !important;
  font-size: 15px !important;
  color: #00A1DE;;
}
