table.styled {
    border: 1px solid $lightestgrey !important;
    border-collapse: separate;
    border-spacing: 0;
    border-radius: $border-radius;
    overflow: hidden;
    padding-bottom: 20px;
    width: 100%;
    margin-bottom: 20px;
    thead {
        background-color: $modal-header-bg;
        th {
            @include formata('medium');
            color: $darkgrey;
            font-size: 15px;
            line-height: 18px;
            padding: 20px 10px 15px;
            text-transform: uppercase;
            &:first-child {
                width: 0;
            }
            &:last-child {
                text-align: center;
            }
        }
        
    }
    td {
        padding: 10px 10px 0;
        &:last-child {
            width: 0;
        }
        
    }
    .table-icon {
        text-align: center;
    }
    @include respond-below('md') {
        border: 0;
        thead {
            border: none;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }
        tr {
            border-bottom: 3px solid $lightgrey;
            display: block;
            margin-bottom: .625em;
        }
        td {
            border-bottom: 1px solid $lightgrey;
            display: block;
            font-size: .8em;
            padding-left: 50%;
            &.table-icon {
                display: none;
            }
            &::before {
                @include formata('regular');
                content: attr(data-label);
                text-transform: uppercase;
                position: absolute;
                left: 30px;
                max-width: 50%;
                padding-right: 60px;
            }
            &:last-child {
                border-bottom: 0;
                padding-bottom: 10px;
            }
        }
    }
}

cx-page-layout.LandingPage2Template, cx-page-layout.ContentPage1Template cx-page-slot.Section2A, cx-page-layout.ProductDetailsPageTemplate{
    max-width: 1920px !important;
    margin: auto;
}

cx-page-slot.Summary, cx-page-slot.Tabs{
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 1px 1px 2px #e3e3e3;
}

.ProductDetailsPageTemplate cx-page-slot.Summary cx-product-summary{
    background: #eef4f6;
    padding: 15% 10%;
    border-top-right-radius: 10px;

    h4{
        font-family: "formata-medium", "arial", sans-serif;
        font-size: 20px;
        color: #37424A;
    }
}

cx-page-layout.ProductDetailsPageTemplate cx-page-slot.Summary{
    grid-template-columns: 1.2fr 1fr !important;
    padding: 0 !important;
}

.ProductDetailsPageTemplate cx-page-slot.Summary cx-product-images{
    color: #00a1de;
    padding: 6% 8%;

    .titleTwo{
        font-size: 76px;
        line-height: 76px;
        font-family: "formata-medium", "arial", sans-serif;
    }

    .titleOne{
        margin-left: 0.3%;
        font-size: 25px;
        font-family: "formata-medium", "arial", sans-serif;
    }

    cx-page-slot.Summary cx-product-summary div hr, hr{   
        width: 16.4%;
        background: #333;
        height: 1px;
        margin-left: 0.5%;
    }

    .productContent{
        color: #37424A;
        font-size: 16px;
        font-family: "formata-light", "arial", sans-serif;
        margin-top: 10px;
    }
}

cx-page-slot.Summary cx-product-summary div hr{
    background: #A5ACAF;
}

cx-page-layout.ProductDetailsPageTemplate{
    padding: 3% 6% !important;
    @include respond-below('sm') {
        padding: 15px !important;
    }
    background: var(--cx-color-background);  
}

cx-product-intro > div.rating, cx-page-slot.Summary cx-product-intro, cx-page-slot.Summary cx-add-to-cart, cx-page-slot.ProductLeftRefinements, cx-product-intro > div.code, cx-add-to-cart .quantity label{
    display: none !important;
}

cx-product-summary > div > form.ng-valid{
    margin-top: 3%;
}

cx-product-summary > div > form.ng-valid > button{
    max-width: 250px !important;
    border-radius: 20px !important;
    border: none;
    color: #fff;
    text-transform: uppercase;
    font-size: 13px;
    padding: 1% 5%;
    font-family: "formata-medium", "arial", sans-serif!important;
}

.addWhishlist{
    max-width: 250px;
    cursor: pointer;
    margin-top: 3%;
}

cx-product-view .cx-product-layout:hover .fas{
    color: #00A1DE !important;
}

cx-item-counter > button:hover{
    color: #00A1DE !important;
}

.noResult{
    text-align: center; 
    color: var(--cx-color-secondary);
}

.headerMSDStable{
    background-color: #EEF4F6;
    color: #37424A;
}

.titleMSDS{
    padding: 10px;
    width: 25%;
    text-transform: uppercase;
    font-size: 15px;
    font-family: "formata-medium", "arial", sans-serif;
    font-weight: normal;

    &:first-child{
        border-top-left-radius: 10px;
    }

    &:last-child{
        border-top-right-radius: 10px;
    }
}

.filters select option{
    color: #212738 !important;
}

.elementMSDS{
    width: 25%;
    padding: 10px;
    font-family: "formata-regular", "arial", sans-serif;
    color: #37424A;
}

.pageContainer{
    float: right;
}

.arrowPagination{
    font-size: 15px;
    border: unset;
    background-color:unset;
    color: #00A1DE;
}

.downloadButton{
    background: #00A1DE;
    color: #fff;
    border-radius: 20px;
    padding: 5px 10px;
    text-transform: uppercase;
    font-size: 12px;
    font-family: "formata-medium", "arial", sans-serif;
    border: none;
}

.claimsElementPDP{
    width: 10%;
    border: 1px solid white;
    padding: 10px;
    text-align:center;
}

.headerPDStable{
    background-color: #EEF4F6;
    color: #37424A;
}

.titlePDS{
    padding: 10px;
    text-transform: uppercase;
    font-size: 15px;
    font-family: "formata-medium", "arial", sans-serif;
    font-weight: normal;
    //border: 1px solid white;
    //color: white;
    //width: 25%;
}

.elementPDS{
    width: 66%;
    padding: 10px;
    font-family: "formata-regular", "arial", sans-serif;
    color: #37424A;
}
.elementPDS-D{
    padding: 10px;
    font-family: "formata-regular", "arial", sans-serif;
    color: #37424A;
}

cx-storefront.ProductDetailsPageTemplate cx-page-slot.BottomHeaderSlot > cx-breadcrumb nav span:not(:last-child)::after {
    content: "|";
    color: #707070;
}

cx-storefront.ProductDetailsPageTemplate cx-page-slot.BottomHeaderSlot > cx-breadcrumb nav span a {
    text-transform: uppercase;
}

.__pdsUppercase{
    text-transform: uppercase;
}