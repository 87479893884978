.owl-theme .owl-nav [class*=owl-] {
    background: transparent !important;
}
.owl-theme .owl-nav .owl-prev {
    position: absolute;
    width: 85px;
    height: 52px;
    top: 60px;
    left: -45px;
    bottom: 0;
    background: $white;
}
.owl-theme .owl-nav .owl-next {
    position: absolute;
    width: 85px;
    height: 52px;
    top: 60px;
    right: -30px;
    bottom: 0;
    background: $white;
}
@include respond-below('sm') {
    .owl-carousel {
        width: 90vw;
    }
}