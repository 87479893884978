table.styled  {
    border: 1px solid $lightestgrey;
    border-collapse: separate;
    border-spacing: 0;
    border-radius: $border-radius;
    margin-bottom: 20px;               
    overflow: hidden;
    padding-bottom: 0;
    position: relative;
    width: 100%;
    thead {
        background-color: $modal-header-bg;
        th {
            @include formata('medium');
            color: $darkgrey;
            font-size: 13px;
            line-height: 18px;
            padding: 20px 10px 15px;
            text-transform: uppercase;
            white-space: nowrap;
            &:last-child {
                text-align: center;
            }
        }
        
    }
    td {
        cursor: pointer;
        font-size: 15px;
        line-height: 18px;
        padding: 10px 10px;
        @include respond-below('md') {
            padding: 10px;
        }
        &:last-child {
            width: 8%;
        }
        &.elementPDS {
            @include respond-below('sm') {
                width: 100%;
            }
        }
    }
    .table-icon {
        text-align: center;
    }
    @include respond-below('md') {
        border: 0 !important;
        thead {
            border: none;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }
        tr {
            border-bottom: 3px solid $lightgrey;
            display: block;
            margin-bottom: .625em;
        }
        td {
            border-bottom: 1px solid $lightgrey;
            display: block;
            font-size: .8em;
            padding-left: 50%;
            &.table-icon {
                display: none;
            }
            &::before {
                @include formata('regular');
                content: attr(data-label);
                text-transform: uppercase;
                position: absolute;
                left: 0;
                max-width: 50%;
                padding-right: 40px;
                white-space: nowrap;
            }
            &:last-child {
                border-bottom: 0;
                padding-bottom: 10px;
            }
        }
    }
}