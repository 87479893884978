cx-page-layout.LandingPage2Template cx-page-slot.Section2A, cx-page-layout.LandingPage2Template cx-page-slot.Section2B {
    --cx-flex-basis: 100% !important;
}

cx-page-slot.SiteContext cx-site-context-selector label{
    display: none;
}

.iframe {
    min-height: 320px;
}