@media (min-width: 768px){
  .xseven-cols .col-md-7,
  .xseven-cols .col-sm-7,
  .xseven-cols .col-lg-7  {
    width: 100%;
    *width: 100%;
    max-width: 100% !important;
    flex: unset;
    padding-left:8px; 
    padding-right:8px;
  }
}

@media (min-width: 992px) {
  .seven-cols .col-md-7,
  .seven-cols .col-sm-7,
  .seven-cols .col-lg-7 {
    width: 14.285714285714285714285714285714%;
    *width: 14.285714285714285714285714285714%;
    max-width: 14.285714285714285714285714285714% !important;
    flex: unset;
    padding-left:8px; 
    padding-right:8px;
  }
}


@media (min-width: 1200px) {
  .seven-cols .col-md-7,
  .seven-cols .col-sm-7,
  .seven-cols .col-lg-7 {
    width: 14.285714285714285714285714285714%;
    *width: 14.285714285714285714285714285714%;
    max-width: 14.285714285714285714285714285714% !important;
    flex: unset;
    padding-left:8px; 
    padding-right:8px;
  }
}
