cx-page-layout.LoginPageTemplate{
    background-color: var(--cx-color-background);
}

cx-form-errors > p {
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 4px;
    color: #8a6d3b;
    background-color: #fcf8e3;
    border-color: #faebcc;
}

cx-form-errors p::before {
    display: none !important;
}

cx-form-errors p::after {
    display: none !important;
}

.btn-link:hover{
    color: #00a1de !important;
    text-decoration: underline;
}

div.checkbox > label > a {
    color: #00a1de !important;
}

form.ng-pristine > button, form.ng-valid > button, form.ng-invalid > button , form.ng-dirty > button, form.ng-touched > button {
    background-color: #00a1de !important;
    border-color: #00a1de !important;
}

.siteLoginLink{
    padding: 0px 5px;
}
.cx-login-greet{
    display: none;
}