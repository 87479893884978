.titleClaim{
    font-family: "formata-regular", "arial", sans-serif;
}

.titleClaimDialog{
    color: #00A1DE;
    font-size: 20px;
}

.selectedFilterRow{
    background-color: #00A1DE !important;
}

cx-page-layout.ContentPage1Template{
    max-width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
}

.img-filter{
    max-height: 20px;
}

.cdk-overlay-pane {
    width: 50% !important;
    max-height: 70% !important;
}

.mat-dialog-container{
    padding: 0 !important;
}

a.contactUs:hover{
    color: white !important;
    text-decoration: unset !important;
}

.errorMessage{
    text-align: center;
    color: var(--cx-color-secondary);
    margin-bottom: 30px;
}

.contacUsError{
    margin-bottom: 30px;
}

.contactUs{
    background-color: #00A1DE;
    border: 1px solid;
    border-radius: 30px;
    padding: 20px;
    font-size: 20px;
    color: white;
    cursor:pointer;
}

.category{
    margin-top: 10px;
    width: 100%;
    background-color: #173C5D;
    min-height: 50px;
    display: flex;
    border-radius: 10px 10px 0px 0px;
    align-items: center;
    padding: 10px 0;
}

.categoryNotSelected{
    border-radius: 10px !important;
}

.filterName{
    color: white !important;
    float: left;
    color: white;
    margin: 0 !important;
    display: flex;
    align-items: center;
    font-size: 15px !important;
    text-transform: uppercase;
}

.showFilters{
    float: right;
    width: 10%;
    height: 45px;
    padding-left: 20px;
    background-color: unset;
    text-align: left;
    border-color: unset;
    font-size: 1em !important;
    border-style: none;
    margin: 0;
    font-size: 25px;
    margin-right: 15px;
    color: #00A1DE;
}

.filters-style{
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0px 0px 10px 10px;
    padding: 5px;
    background-color:white;
}

.filter-button {
    display: flex;
    border-radius: 30px;
    font-size: 15px;
    line-height: 19px;
    border: 1px solid $blue;
    color: $blue;
    margin: 5px;
    padding: 10px 15px 10px 20px;
    float: left;
    max-width: 100%;
    background-color: $white;
    div {
        @include formata('medium');
        color: $darkgrey;
        text-align: left;
    }
    ~.filter-button {
        margin-left: 5px;
    }
}

.selectedFilter{
    max-height: 20px;
    font-size: 15px;
    color: #00A1DE
}

.removeFilter{
    cursor: pointer;
}

.disableFilter{
    color: #00A1DE;
    float: right;
    font-size: 20px;
    margin-left: 10px
}

.openDialogFiltri{
    cursor: pointer;
    width: 100%;
}

.widthCard{
    max-width: 22% !important;
}

.floatButton{
    float: right;
}

.maxHeight{
    max-height: 40px;
}

.claimCardRow{
    border-radius: 10px;
    min-height: 100px;
    padding: 0 !important;
    margin: 10px;
    background-color: #fff;
}

.claimCardContainer{
    width: 100%;
    background-color: rgba(165, 172, 175, 0.1);
    min-height: 50px;
    padding: 20px 15px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    margin-bottom: 0.75rem;
}

.blueInform{
    color: #00A1DE;
}

.grey{
    color: #A5ACAF;
}

.openDialog{
    cursor: pointer;
}

.claimCardDetails{
    width: 100%;
    min-height: 50px;
    padding: 5px 30px;
}

.rowDetails{
    margin-bottom: 15px;
    margin-left: 5px;
}

.categoryImg{
    max-height: 20px;
}

.rowData{
    margin-bottom: 15px;
    margin-left: 5px;
}

.containerSpecsCount{
    margin-bottom: 0;
    width: 100%;
    background-color: rgba(165, 172, 175, 0.1);
    min-height: 50px;
    padding: 5px 30px;
    border-radius: 10px;
    display: flex;
    align-items: center;
}

.specsCount{
    color: #00A1DE;
    margin-right: 5px;
}

.points{
    color :#00A1DE;
    margin: 0 10px;
}

.firstNumberPaginator{
    margin: 0 10px;
}

.numberPaginator{
    margin-right: 10px;
}

.activeArrow{
    background-color: #00A1DE;
    color: white;
}

.disableArrow{
    background: unset;
    color:#00A1DE;
}

.pn{
    border-radius: 30px;
    font-size: 15px;
    padding: 10px;
    display: block;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    border: 1px solid #00A1DE;
}

.backArrow:disabled, .arrow:disabled{
    cursor: not-allowed;
}