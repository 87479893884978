.btn {
    @include formata('light');
    color: $white;
    font-size: 15px;
    background-color: $blue;
    text-transform: uppercase;
    border: 2px solid $blue;
    border-radius: 30px;
    height: 52px;
    letter-spacing: .5px;
    line-height: 0;
    padding-right: 30px;
    padding-left: 30px;
    position: relative;
    text-align: left;
    text-transform: uppercase !important;
    width: 100%;
    justify-content: space-between;
    display: flex;
    align-items: center;
    max-width: 226px;
    &.btn-primary {
        @include formata('light');
        color: $white;
        font-size: 15px;
        background-color: $blue;
        text-transform: uppercase;
        border: 2px solid $blue;
        border-radius: 30px;
        height: 52px;
        letter-spacing: .5px;
        padding-left: 30px;
        position: relative;
        text-align: left;
        width: 100%;
        justify-content: space-between;
        display: flex;
        align-items: center;
        max-width: 226px;
        @include respond-below('sm') {
            //max-width: 100%;
        }
        &.w-auto {
            padding-right: 62px;
        }
        
        &:hover, &:active, &:focus {
            text-decoration: none !important;
            background-color: $darkblue !important;
            border-color: $blue !important;
            color: $white !important;
            filter: none;
        }
        &:after {
            color: $darkgrey;
            content: "\f054";
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            position: absolute;
            right: 16px;
        }
        &[disabled] {
            background-color: $white;
            border-color: $blue;
            color: $blue;
            pointer-events: none;
        }
    }
    &.btn-back {
        text-align: right;
        width: auto;
        padding: 0 35px 0 50px;
        &:before {
            color: $darkgrey;
            content: "\f053";
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            position: absolute;
            left: 16px;
        }
        &:after {
            content: '';
        }
    }
    &.btn-spinner {
        &:after {
            animation: fa-spin 2s linear infinite;
            content: "\f110";
        }
    }
    &.btn-ghost {
        background-color: transparent;
        border: 2px solid $white;
        border-radius: 30px !important;
        color: $white;
        padding: 5px 30px;
        @include respond-below('md') {
            padding: 5px 20px;
        }
        &:after {
            color: $blue;
        }
        &:hover, &:active, &:focus {
            color: $darkestblue !important;
            background: $white !important;
            border-color: $white !important;
        }
    }
    &.btn-ghost-inverse {
        font-size: 14px;
        background-color: transparent;
        border-radius: 30px !important;
        border: 1px solid $blue;;
        color: $blue;
        min-width: 120px;
        &:after {
            //color: $white;
            color: $darkgrey;
        }
        &:hover, &:active, &:focus {
            color: $white !important;
            background: $blue !important;
            border-color: $blue !important;
        }
    }
    &.btn-icon {
        @include formata('regular');
        align-items: center;
        color: $white;
        cursor: pointer;
        background-color: $blue;
        border: 1px solid $blue;
        border-radius: 30px;
        display: flex;
        font-size: 13px;
        height: fit-content;
        justify-content: center;
        line-height: 16px;
        min-height: 40px;
        padding: 6px 30px 5px 45px;
        position: relative;
        text-transform: uppercase;
        max-width: 100%;
        width: fit-content;
        &:before {
            content: '';
            margin-right: 10px;
            margin-left: -25px;
        }
        &.icon-drop {
             &:before {
                 content: url('/assets/icons/Droplet.svg');
                 filter:  brightness(0) invert(1);
             }
        }
        &.icon-star {
             &:before {
                 margin-right: 5px;
                 width: 25px;
                 height: 25px;
                 content: url('/assets/icons/Star.svg');
             }
             &.added:before {
                 content: url('/assets/icons/StarSolid.svg');
             }
        }
        &.bg-transparent:hover {
            border-color: $blue !important;
            color: $blue !important;
            &:not(.added):before {
                filter: $blue-filter;
            }
        }
       
    }
    &.btn-plain {
        @include formata('medium');
        font-size: 12px;
        width: auto;
        padding: 10px 15px;
        height: unset;
        border-width: 2px;
        min-width: inherit;
        &:after {
            content: '';
        }
    }
    &.btn-simple {
        min-width: auto;
        width: auto;
        padding: 5px 30px;
        &:after {
            content: '';
        }
    }
    &:hover {
        cursor: pointer;
        
    }
    &.shadow-none{
        box-shadow: none;
    }
}
.border-blue {
    border-color: $blue !important;
}