.page {
    margin: auto;
    max-width: 860px;
    padding: 80px 0;
    &.page-contact {
        max-width: 1080px;
    }
    h1 {
        
    }
    h2 {
        @include formata('light');
        font-size: 50px !important;
        line-height: 59px;
        margin-bottom: 20px !important;
        padding-top: 45px;
        position: relative;
        text-transform: uppercase;
        span {
            @include formata('medium');
        }
        &:before {
            content: '';
            width: 209px;
            background: $blue;
            height: 2px;
            left: 50%;
            right: 50%;
            margin: auto;
            top: 0;
            position: absolute;
            transform: translateX(-50%)
        }
    }
    h3 {
        @include formata('medium');
        font-size: 24px;
        line-height: 30px;
        margin-top: 30px;
        text-transform: uppercase;
    }
    &--intro {
        @include formata('light');
        font-size: 21px;
        line-height: 29px;
        margin-bottom: 45px !important;
        a {
            color: $blue;
        }
    }
    &--icon {
        margin-bottom: 30px;
    }
    a {
        color: $blue;
    }
    p {
        @include formata('light');
        color: $darkgrey;
        font-size: 16px;
        line-height: 30px;
    }
    ul:not(.nav, .list-unstyled) {
        font-size: 16px;
        line-height: 30px;
        list-style: none;
        padding-left: 20px;
        li {
            position: relative;
            &:before {
                content: '';
                background-color: $blue;
                display: inline-block;
                width: 6px;
                margin-left: -15px;
                height: 6px;
                border-radius: 50%;
                position: absolute;
                top: calc(50% - 3px);
            }
        }
    }
    hr {
        margin-top: 2.5rem;
        margin-bottom: 2.5rem;
        border: 0;
        border-top: 1px solid rgba(23, 60, 93, .3);
    }
}
.banner {
    background-color: #00a1de;
    text-align: center;
    min-height: 170px;
    display: flex;
    align-items: center;
    vertical-align: middle;
    h1 {
        @include formata('medium');
        color: $white;
        font-size: 50px;
        line-height: 38px;
        padding-top: 25px;
        position: relative;
        text-transform: uppercase;
        &:before {
            content: '';
            background: $darkestblue;
            display: block;
            height: 2px;
            margin: auto;
            width: 110px;
            top: 0;
            position: absolute;
            left: 0;
            right: 0;
        }
    }
}