.alert-password{
    max-width: var(--cx-max-width, 50%);
    width: 100%;
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 4px;
    color: #8a6d3b;
    background-color: #fcf8e3;
    border-color: #faebcc;
}