.page-pagination {
    border-top: 1px solid $lightgrey;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid $lightgrey;
    margin-bottom: 30px;
    @include respond-below('md') {
        flex-direction: column;
    }
    .numberResults {
        font-size: 18px;
        line-height: 19px;
        @include respond-above('sm') {
            margin-left: 20px;
        }
    }
}
.my-pagination .ngx-pagination {
    line-height: 33px;
    margin-top: 10px;
    padding-left: 0;
    display: flex;
    align-items: end;
    justify-content: flex-start;
    gap: 5px;
    
    .small-screen {
        display: none;
        @include respond-below('md') {
            display: block;
            width: max-content;
            padding: 0 20px;
            border-radius: 30px;
        }   
    }
    li {
      border-radius: 30px;
      display: inline-flex;
      color: $blue;
      font-size: 15px;
      height: 40px;
      width: 40px;
      border-radius: 50%;
      border: 2px solid $blue;
      text-align: center;
      justify-content: center;
      a {
            height: 100%;
            display: block;
            position: relative;
            z-index: 2;
            @include respond-below('md') {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
            } 
          &:before {
              content: none !important;
          }
      }
      &:not(.small-screen) {
          @include respond-below('md') {
                display: none;
          }
      }
      span {
          @include formata('regular');
      }
      &:hover {
          background-color: $blue;
          cursor: pointer;
          span {
            color: $white;
        }
      }
      &.ellipsis {
          border: none !important;
          width: min-content;
          a {
              align-content: baseline;
              display: inline-flex;
              padding: 0;
              width: max-content;
              &:before {
                  @include formata('medium');
                  content: '. . .';
                  margin-top: 10px;
                  width: max-content;
              }
              span {
                //   display: none;
              }
          }
          &:hover {
              background-color: transparent;
          }
      }
      &.current {
          background-color: $blue;
          color: $white;
      }
      &.disabled {
          pointer-events: none;
          &:before {
              border-color: rgba(0, 161, 222, .5);
          }
      }
      &:before, &:after {
          content: none;
          margin: 0 auto !important;
          padding: 0;
          width: fit-content;
          align-self: center;
          height: 25px;
          @include respond-above('md') {
              position: absolute;
          }
      }
      &.pagination-previous {
            position: relative;
            margin-right: 40px;
            @include respond-below('md') {display: flex !important;}
            &:before {
                content: url('/assets/filterArrow.svg');
                transform: scaleX(-1);
            }
            &:after {
                content: '';
                height: 40px;
                width: 1px;
                background: $lightgrey;
                position: absolute;
                right: -23px;
                top: 0;
            }
            :after {
                content: none;
            }
            &:hover {
                &:before {
                    filter:  brightness(0) invert(1);
                }
            }
            &.disabled {
                border-color: rgba(0, 161, 222, .5);
                &:before {
                    opacity: .5;
                }
            }
            @include respond-below('md') {
                display: block;
            }
      }
      &.pagination-next {
            position: relative;
            margin-left: 40px;
            @include respond-below('md') {display: flex !important;}
            &:before {
                content: url('/assets/filterArrow.svg');
            }
            &:after {
                content: '';
                height: 40px;
                width: 1px;
                background: $lightgrey;
                position: absolute;
                left: -23px;
                top: 0;
            }
            &.disabled {
                border-color: rgba(0, 161, 222, .5);
                &:before {
                    opacity: .5;
                }
            }
            &:hover {
                &:before {
                    filter:  brightness(0) invert(1);
                }
            }
            a:after {
                content: none !important;
            }
            @include respond-below('md') {
                display: block;
            }
        }
    }
    a:hover,
    button:hover {
        background: unset;
    }
}