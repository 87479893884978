h1, .h1 {
    position: relative;
    &.border-top-blue {
        @include formata('light');
        .text-bold {
            @include formata('medium');
        }
        font-size: 50px;
        line-height: 60px;
        padding-top: 30px;
        text-transform: uppercase;
        &:before {
            content: '';
            background-color: $blue;
            height: 2px;
            width: 209px;
            position: absolute;
            top: 0;
            margin: auto;
            left: 0;
            right: 0;
        }
    }
    &.border-align-center {
        &:before {
            left: 0;
            margin: auto;
            right: 0;
        }
    }
}